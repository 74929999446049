define("ember-get-config/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "modulePrefix": "@ritual/account-manager",
    "environment": "production",
    "rootURL": "/",
    "locationType": "auto",
    "i18n": {
      "defaultLocale": "en-us"
    },
    "EmberENV": {
      "FEATURES": {},
      "EXTEND_PROTOTYPES": {
        "Date": false
      },
      "_APPLICATION_TEMPLATE_WRAPPER": true,
      "_JQUERY_INTEGRATION": false,
      "_TEMPLATE_ONLY_GLIMMER_COMPONENTS": true
    },
    "activeEnv": "production",
    "host": "https://api.ritual.com",
    "namespace": "api/v1",
    "homeUrl": "https://ritual.com",
    "checkoutUrl": "https://checkout.ritual.com/",
    "cloudflareHost": "https://ritual.com",
    "mapbox": {
      "accessToken": "pk.eyJ1Ijoicml0dWFsIiwiYSI6ImNqc3A0am1iZDAwbGQzeW50Y2czdGR3dDkifQ.j0zu-r1hQqBiZSqP-OvJtQ",
      "style": "mapbox://styles/ritual/cjrazz5rq12892sqllgly2lik"
    },
    "stripe": {
      "publishableKey": "pk_live_WMeJN8QujSCDgIcJK5E5XENB",
      "lazyLoad": true
    },
    "braintree": {
      "authorization": "production_vn9k3qpm_stjhqx2btgxvpc9s",
      "environment": "production"
    },
    "contentful": {
      "space": "uuc5ok478nyh",
      "accessToken": "04a9c2392b70b2ec8f57a15969717b9a7834b78464f16d50df155e7038c07588",
      "previewAccessToken": "31be2742961f379528ee536585bf4f5d5a170d988e3edea0ba0d0bf7034050f3",
      "usePreviewApi": false,
      "environment": "master"
    },
    "iterable": {
      "signupId": "08bee912-e641-4a8e-b7da-c31e943df885"
    },
    "castlePublishableKey": "pk_gTPsEZK6PgPkpmEsxGT9b6DVZy9aAdrj",
    "launchDarklyClientId": "5b526140f7db352ec53debcb",
    "segmentWriteKey": "i7w0QiJzs86wBaM1h1xDI26GELQtgKGi",
    "homeSegmentWriteKey": "jBKkKOGmXz9UdedZorYIp3eZemJ18P5f",
    "checkoutSegmentWriteKey": "ggPRI3y1YeiyKA4HFVpyVs0bpXAvpCPT",
    "smartyStreetsWebskiteKey": "19369788519939256",
    "appleRedirectUri": "https://account.ritual.com/callback",
    "appleClientId": "com.ritual.prod",
    "googleClientId": "175608687555-k2i1dejkfl34t4qocrf3d169q7bst3h5.apps.googleusercontent.com",
    "APP": {
      "imgix": {
        "source": "ritual.imgix.net",
        "debug": false
      },
      "validatorDefaultLocale": "en",
      "name": "@ritual/account-manager",
      "version": "1.16.0"
    },
    "contactUrl": "https://help.ritual.com/",
    "faqUrl": "https://help.ritual.com/",
    "legacyPlansCutoffDate": "2024-03-30T00:00:00.000Z",
    "currentApp": "account",
    "cookieDomain": ".ritual.com",
    "currentRevision": "6b494d4726de57f74684c48774fc64567d12b03e",
    "bugsnag": {
      "apiKey": "7c5302171d1593d043865c46cd2e4c40",
      "releaseStage": "production",
      "appVersion": "v1565"
    },
    "contentSecurityPolicy": {
      "style-src": "'self' 'unsafe-inline' 'unsave-eval'"
    },
    "exportApplicationGlobal": false,
    "ember-faker": {},
    "ember-load": {
      "loadingIndicatorClass": "ember-load-indicator"
    }
  };
});